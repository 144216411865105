import { useEffect, useRef } from 'react';

export function useDidUpdateEffect(fn, inputs, enableFirstEffect = false) {
  const isMountingRef = useRef(false);
  useEffect(() => {
    isMountingRef.current = true;
  }, []);

  useEffect(() => {
    if (!isMountingRef.current || enableFirstEffect) {
      return fn();
    } else {
      isMountingRef.current = false;
    }
  }, inputs);
}
