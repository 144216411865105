import { useCallback, useEffect, useRef } from 'react';
import { useAuth } from './useAuth';
import { useCurrentUser } from './useCurrentUser';
import {
  addOnRouteChange,
  getCurrentRoute,
  goToRoute,
  goToRouteUrl,
} from '@arivaa-react/react-router5';
import { getDefaultSecuredRoute } from '../routes';
import { message } from 'antd';
import { useFeatureAccess } from './useFeatureAccess';

export const useAfterAuth = (onLogin, onLogout) => {
  const { auth } = useAuth();
  const prevAuthRef = useRef();

  const { getCurrentUser, user } = useCurrentUser();
  const { hasAccess } = useFeatureAccess();

  const canUserAccessRoute = useCallback(() => {
    const route = getCurrentRoute();
    const allowed = hasAccess(route.roles, route.permissions);
    if (!allowed) {
      goToRoute(getDefaultSecuredRoute());
      message.error('You are not authorized to access this page');
    }
    return allowed;
  }, [user]);

  const afterLogin = useCallback(async () => {
    const user = await getCurrentUser();
    onLogin?.(user);
  }, [onLogin]);

  const redirectAfterLogin = useCallback(() => {
    const route = getCurrentRoute();
    const urlParams = new URLSearchParams(window.location.search.substring(1));
    const nextRoute = urlParams.get('_next');
    /**
     * if the user is not allowed then redirect to home
     */
    if (canUserAccessRoute()) {
      if (nextRoute) {
        goToRouteUrl(nextRoute, {
          search: urlParams.get('_next_query'),
        });
      } else {
        // If page is public but not allowed to logged in users e.g login users redirect to default route
        if (route.public && route.guestOnly) {
          goToRoute(getDefaultSecuredRoute());
        }
      }
    }
  }, [user]);

  useEffect(() => {
    if (auth && !prevAuthRef.current) {
      afterLogin();
    } else if (!auth && prevAuthRef.current) {
      onLogout?.();
    }
    prevAuthRef.current = auth;
  }, [auth, onLogin, onLogout]);

  useEffect(() => {
    if (user) {
      redirectAfterLogin();
      return addOnRouteChange(canUserAccessRoute);
    }
  }, [user]);
  return null;
};
