import { ForgotForm } from '../../containers/authentication/forgotForm/index';
import { DEFAULT_LOGIN_TYPE } from '../../containers/authentication/defaultLoginLink';

/**
 * @description Forgot Password
 * @type Page
 * @author
 */
const Forgot = ({ match }) => {
  return <ForgotForm type={match?.params?.type || DEFAULT_LOGIN_TYPE} />;
};

Forgot.displayName = 'Forgot';
Forgot.routeProps = {
  header: true,
  public: true,
  guestOnly: true,
};
Forgot.url = 'forgot/:type?';
export default Forgot;
