import { HeaderActions } from './headerActions';

export function Header({ title, children }) {
  return (
    <div className="data-header">
      <span className="title">{title}</span>
      <div className="right">{children}</div>
    </div>
  );
}

Header.Actions = HeaderActions;
