import { Error } from '../../containers/error/index';
const Error404 = () => (
  <Error
    code="404"
    title="Page not found"
    description="The page you are looking for does not exist."
  />
);

Error404.routeProps = {
  header: true,
  public: true,
  guestOnly: true,
};

export default Error404;
