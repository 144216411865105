/**
 * This exports all the general client app configuration
 */

import * as TranslationsConfig from './translations';
export const translations = TranslationsConfig.default;
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export function configureLocalization() {
  const formattedTranslations = {
    en: {
      translation: {},
    },
  };

  Object.values(translations).forEach((value) => {
    Object.keys(value).forEach((key) => {
      formattedTranslations.en.translation[key] = value[key][0];
    });
  });

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: formattedTranslations,
      lng: 'en', // if you're using a language detector, do not define the lng option
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    });
}

export function translate(...args) {
  return i18n.t(...args);
}
