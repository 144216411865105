import { parseJSON } from '@arivaa-react/helpers/common';
import { useAuthApi } from './useAuthApi';
export const AUTH_STORAGE_KEY = 'arivaa-suite-auth';
export const AUTH_REMEMBER_ME_KEY = 'arivaa-suite-remember-me';
export function setRememberMePreference(rememberMe) {
  window?.localStorage?.setItem(AUTH_REMEMBER_ME_KEY, rememberMe);
}

export function getAuthStorage() {
  const rememberMe =
    window?.localStorage?.getItem(AUTH_REMEMBER_ME_KEY) === 'true';
  return rememberMe ? window.localStorage : window.sessionStorage;
}

/**
 * Save Auth Data to local storage
 * @param {*} auth
 */
export function saveAuthData(auth) {
  getAuthStorage().setItem(AUTH_STORAGE_KEY, JSON.stringify(auth));
}
/**
 * Get Auth Data from local storage
 * @param {*} auth
 */
export function getAuthData() {
  let auth = getAuthStorage()?.getItem(AUTH_STORAGE_KEY);
  auth = auth && parseJSON(auth);
  return auth;
}
/**
 * Remove Auth Data from local storage
 * @param {*} auth
 */
export function clearAuthData() {
  getAuthStorage().removeItem(AUTH_STORAGE_KEY);
}

const authStorage = {
  save: saveAuthData,
  get: getAuthData,
  clear: clearAuthData,
}

export function useAuth() {
  const authApi = useAuthApi(authStorage);
  return {
    ...authApi,
    login: ({ rememberMe = false, ...values }) => {
      setRememberMePreference(rememberMe !== false);
      return authApi.login(values);
    },
  };
}
