import { OtpVerification } from '../../containers/otpVerificationForm/index';
/**
 * @description Sign Up  via phone
 * @type Page
 * @author
 */
const Verify = () => {
  return <OtpVerification />;
};

Verify.routeProps = {
  header: true,
  public: true,
  guestOnly: true,
};

Verify.displayName = 'Verify';
export default Verify;
