import { useCallback, useRef } from 'react';
import { Layout, Menu } from 'antd';
import './styles.scss';
import {
  getCurrentRoute,
  getRoute,
  goToRoute,
} from '@arivaa-react/react-router5';
import { mobileAndTabletcheck } from '@arivaa-react/helpers/web';

import logo from '../../images/laxaar-logo.png';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useDrawer } from '../../hooks/useDrawer';
import { translate } from '../../localization';
import { useFeatureAccess } from '../../hooks/useFeatureAccess';

const { Sider: AntSider } = Layout;

export const SideBar = ({ menuKeys }) => {
  //lower camel case
  const { permissions, role } = useCurrentUser().user;
  const { hasAccess } = useFeatureAccess();
  const { setDrawer, drawer } = useDrawer();

  const menuRef = useRef(null);
  const { routeKey } = getCurrentRoute();
  const getMenuItems = useCallback((menus) => {
    return menus.map((item) => {
      const { icon: Icon, name, key, children, noTranslate } = item;
      return {
        key,
        icon: <Icon />,
        label: noTranslate ? name : translate(name),
        children: children ? getMenuItems(children) : null,
      };
    });
  }, []);
  //index.js
  const menus = menuKeys.filter((item) => {
    const routeProps = (getRoute(item.key) || {}).routeProps || {};
    return (
      role &&
      hasAccess(routeProps?.roles, routeProps?.permissions) &&
      (item.isAllowed instanceof Function
        ? !!item.isAllowed(permissions, role)
        : true)
    );
  });

  return role ? (
    <AntSider
      collapsible={true}
      collapsed={!drawer}
      className="sider"
      collapsedWidth={60}
      breakpoint="md"
      width={280}
    >
      <div className="brand">
        <img src={logo} />
      </div>
      <Menu
        theme="light"
        mode="inline"
        ref={menuRef}
        onClick={(e) => {
          goToRoute(e.key);
          mobileAndTabletcheck() && setDrawer(false);
        }}
        selectedKeys={menus
          .filter((item) => {
            return item.key === routeKey;
          })
          .map((item) => {
            return item.key;
          })}
        items={getMenuItems(menus)}
      />
    </AntSider>
  ) : null;
};
