import { Button, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useCrudData } from '../crudContext';
import { secureApi } from '../../../hooks/api';
import { useCrudRecord } from '../recordContext';
import { useFeedback } from '../../../hooks/useFeedback';

export function DuplicateRecord() {
  const { apiUrl, refresh } = useCrudData();
  const record = useCrudRecord();
  const { modal } = useFeedback();
  return (
    <Tooltip title="Duplicate">
      <Button
        className="btn yellow-btn-text"
        onClick={() =>
          modal.confirm({
            title: 'Do you want to duplicate this record?',
            onOk: async () => {
              await secureApi(`${apiUrl}/duplicate`, {
                method: 'POST',
                body: {
                  id: record.key,
                },
              });
              refresh();
            },
          })
        }
      >
        <CopyOutlined />
      </Button>
    </Tooltip>
  );
}
