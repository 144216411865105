import { Button, message } from 'antd';
import { ApiForm } from '../../containers/apiForm';
import { useCrudData } from './crudContext';
import { getObjectsDiffKeys } from '@arivaa-react/helpers/common';
import { useCrudRecord } from './recordContext';
import { forwardRef, useCallback } from 'react';
import { useModal } from '@arivaa-react/components/modal';
import { translate } from '../../localization';

export const CrudForm = forwardRef(
  (
    { data, elements = [], preSubmit, renderForm, onSuccess, ...props },
    ref
  ) => {
    const { apiUrl, refresh } = useCrudData();
    const record = useCrudRecord();
    const { hideModal } = useModal();
    const processElement = useCallback(
      (element) => {
        element = { ...element };
        element.options = {
          ...element.options,
          initialValue: data
            ? data[element.name]
            : element?.options?.initialValue,
        };
        return element;
      },
      [data]
    );
    elements = (elements || []).map((element) => {
      if (element.elements instanceof Array) {
        element.elements = element.elements.map(processElement);
      }
      return processElement(element);
    });
    return (
      <ApiForm
        actions={[
          <Button
            key="cancel"
            htmlType={'button'}
            onClick={hideModal}
            className="btn red-btn-text"
          >
            {translate('common.cancel')}
          </Button>,
        ]}
        submitText={translate('common.submit')}
        {...props}
        data={data}
        elements={elements}
        ref={ref}
        preSubmit={
          data
            ? (values) => {
                const updatedKeys = getObjectsDiffKeys(data, values);
                const updatedValues = {};
                updatedKeys.forEach((key) => {
                  updatedValues[key] = values[key];
                });
                delete values.key;
                preSubmit?.(updatedValues);
                return updatedValues;
              }
            : preSubmit
        }
        apiUrl={data ? `${apiUrl}/${record.key}` : apiUrl}
        apiConfig={{
          method: data ? 'PATCH' : 'POST',
        }}
        onSuccess={function () {
          message.success(translate('common.changes.save.success'));
          refresh();
          onSuccess?.();
          hideModal?.();
        }}
        // Overriding this prop to pass data while rendering element in edit view
        renderForm={
          renderForm
            ? ({ renderElement, ...rest }) => {
                return renderForm({
                  ...rest,
                  renderElement: (obj) =>
                    renderElement({
                      ...obj,
                      options: {
                        ...obj.options,
                        initialValue: data
                          ? data[obj.name]
                          : obj?.options?.initialValue,
                      },
                    }),
                });
              }
            : undefined
        }
      />
    );
  }
);
