import Link from '@arivaa-react/components/link';
import { goToRoute } from '@arivaa-react/react-router5';
export const DEFAULT_LOGIN_TYPE = 'email';

export const DefaultLoginLink = function (props) {
  return (
    <Link
      routeKey="login"
      routeParams={{ type: DEFAULT_LOGIN_TYPE }}
      {...props}
    />
  );
};

export function goToLogin(options = {}) {
  goToRoute('login', {
    routeParams: {
      type: options.type || DEFAULT_LOGIN_TYPE,
    },
    ...options,
  });
}
