import { useEffect } from 'react';
import './styles.scss';
import { renderRoutes } from 'react-router-config';
import { addOnRouteChange, getCurrentRoute } from '@arivaa-react/react-router5';
import { isMobile, scrollToTopWindow } from '@arivaa-react/helpers/web';
import { useDrawer } from '../../hooks/useDrawer';
import { useHorizontalMenu } from '../../hooks/useHorizontalMenu';
import { useOnPageSetup } from '../../hooks/useOnPageSetup';
export const Main = (props) => {
  const { route: propRoute } = props;
  const { horizontalMenu } = useHorizontalMenu();

  const route = getCurrentRoute();
  const { setDrawer } = useDrawer();
  const { header } = route;
  const { pageReady } = useOnPageSetup();
  useEffect(() => {
    addOnRouteChange(() => {
      setTimeout(() => {
        if (isMobile()) {
          setDrawer(false);
        }
        scrollToTopWindow();
      }, 0);
    });
  }, []);
  return (
    <div className="app-container">
      {pageReady ? (
        <>
          {/* {header !== false ? <Header type={header} /> : null} */}
          <div
            className={
              'main-container ' +
              (!route.public ? 'secured ' : '') +
              (header == false
                ? ' no-header '
                : (header && header.type) || '') +
              (horizontalMenu ? ' with-horizontal-menu' : {})
            }
          >
            {renderRoutes(propRoute.routes)}
          </div>
        </>
      ) : null}
    </div>
  );
};
