import { DeleteRecord } from './row-actions/deleteRecord';
import { DuplicateRecord } from './row-actions/duplicateRecord';
import { DuplicateRecordWithForm } from './row-actions/duplicateRecordWithForm';
import { EditRecord } from './row-actions/editRecord';

export function RowActions({ actions = [] }) {
  return (
    <div className="row-actions">
      {actions.map((Action, index) => {
        return <Action key={index} />;
      })}
    </div>
  );
}

RowActions.Edit = EditRecord;
RowActions.Delete = DeleteRecord;
RowActions.DuplicateRecord = DuplicateRecord;
RowActions.DuplicateRecordWithForm = DuplicateRecordWithForm;
