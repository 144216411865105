import React, { useCallback } from 'react';
import { message } from 'antd';
import { ApiForm } from '../../../apiForm';
import { goToRoute } from '@arivaa-react/react-router5';
import { Captcha } from '../../captcha';
import { useVerificationData } from '../../useVerificationData';
import { url } from '../../../../constants/api';
import { captchaEnabled } from '../../../../hooks/useAppConfig';
import { translate } from '../../../../localization';
import { saveAuthData } from '../../../../hooks/useAuth';

/**
 * @description OTP Login Form
 * @type Component
 * @author Inderdeep
 */
export const OtpForm = (props) => {
  const { elements, type } = props;
  const captchaRef = React.useRef(null);
  const { setVerificationData } = useVerificationData();

  const preSubmit = useCallback(
    (values) => {
      captchaEnabled && captchaRef?.current?.reset();
      return {
        [type]: values[type],
      };
    },
    [captchaRef]
  );
  const onSuccess = async (res) => {
    setVerificationData({
      fieldName: 'secret',
      confirmationKey: res?.key,
      confirmationType: type,
      resendApiConfig: {
        method: 'POST',
      },
      api: `authenticate`,
      apiConfig: {
        method: 'POST',
      },
      extra: {
        type: type === 'email' ? 'emailOtp' : 'phone',
      },
      resendApi: `authenticate/resendOtp/${type}`,
      onVerify: (response) => {
        message.success(translate('signUp.accountVerification.success'));
        saveAuthData({
          ...response,
        });
        goToRoute('home', { forceRefresh: true });
      },
    });
    goToRoute('verify');
  };

  if (captchaEnabled) {
    elements.push({
      type: 'custom',
      name: 'captcha',
      label: 'I am not a robot',
      required: true,
      requiredMessage: 'Please Enter the Captcha',
      Component: Captcha,
      inputProps: {
        ref: captchaRef,
      },
    });
  }

  return (
    <ApiForm
      elements={elements}
      apiUrl={url(`authenticate/sendOtp/${type}`)}
      preSubmit={preSubmit}
      onSuccess={onSuccess}
      submitButtonProps={{
        children: 'Login',
      }}
      errorMessage={`No user found with this email. Please sign up to create a new account`}
    />
  );
};
