import { Button, Input } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { translate } from '../../../localization';
import { CrudForm } from '../../../containers/crud/crudForm';

export const SeoForm = function (props) {
  const { data, entityId, fieldName, ...formProps } = props;
  const elements = [
    {
      name: 'name',
      label: translate('systemPage.metaTags.name.label'),
      placeholder: translate('systemPage.metaTags.name.placeholder'),
      required: true,
    },
    {
      name: 'value',
      label: translate('systemPage.metaTags.value.label'),
      placeholder: translate('systemPage.metaTags.value.placeholder'),
      required: true,
    },
  ];
  FormDataEvent;
  return (
    <>
      <CrudForm
        {...formProps}
        data={{
          key: entityId,
        }}
        renderForm={() => {
          return (
            <Form.List name={fieldName} initialValue={data}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} style={{ display: 'flex' }}>
                      {elements.map((element, index) => (
                        <Form.Item
                          key={index}
                          {...restField}
                          name={[name, element.name]}
                          style={{ marginRight: 8, flex: 1 }}
                          rules={[
                            {
                              required: element.required,
                              message: element.name + ' is required',
                            },
                          ]}
                        >
                          <Input
                            placeholder={element.placeholder}
                            label={element.label}
                          />
                        </Form.Item>
                      ))}
                      <DeleteOutlined
                        onClick={() => remove(name)}
                        style={{ marginTop: 6 }}
                      />
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add Tag
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          );
        }}
      />
    </>
  );
};
