import { useRef } from 'react';
import useWatchForm from '@arivaa-react/components/form/useWatchForm';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { ApiForm } from '../../../containers/apiForm';
import { url } from '../../../constants/api';
import { translate } from '../../../localization';
/**
 * Change Password Form
 */
export const ChangeEmailPassword = () => {
  const form = useRef(null);
  const { user } = useCurrentUser();
  const { password, oldPassword, email } = useWatchForm(form) || {};
  return (
    <>
      <p className="page-title">Security</p>
      <div className="section setting security">
        <ApiForm
          ref={form}
          elements={[
            {
              type: 'email',
              name: 'email',
              label: 'Email Address',
              required: true,
              options: {
                initialValue: user.email,
              },
            },
            {
              type: 'password',
              name: 'oldPassword',
              label: 'Old Password',
              required: user.email !== email || (password && password !== ''),
            },
            {
              type: 'password',
              name: 'password',
              label: 'New Password',
              required: false,
              options: {
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      if (value !== '' && value === oldPassword) {
                        callback(
                          translate('resetPassword.error.password.match')
                        );
                      } else {
                        callback();
                      }
                    },
                    name: 'confirmPassword',
                  },
                ],
              },
            },
          ]}
          apiUrl={url(`users/current/update`)}
          apiConfig={{
            method: 'PATCH',
          }}
          submitButtonProps={{
            children: 'Save',
          }}
        />
      </div>
    </>
  );
};
