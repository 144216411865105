import React from 'react';
import { message } from 'antd';
import Link from '@arivaa-react/components/link';
import { goToRoute } from '@arivaa-react/react-router5';
import { goToLogin } from '../../defaultLoginLink';
import { Captcha } from '../../captcha';
import { MailOutlined } from '@ant-design/icons';
import { useVerificationData } from '../../useVerificationData';
import { ApiForm } from '../../../apiForm';
import { url } from '../../../../constants/api';
import { captchaEnabled, isDemoEnabled } from '../../../../hooks/useAppConfig';
import { translate } from '../../../../localization';

/**
 * @description Email Signup Form
 * @type Component
 * @author Inderdeep
 */

export const SignupForm = () => {
  const captchaRef = React.useRef(null);
  const { setVerificationData } = useVerificationData();

  const onSuccess = async ({ confirmationKey, verificationCode }) => {
    if (confirmationKey) {
      setVerificationData({
        confirmationKey,
        verificationCode,
        confirmationType: 'email',
        api: `confirm-email`,
        apiConfig: {
          method: 'PATCH',
        },
        resendApi: `confirm-email/resend`,
        onVerify: () => {
          message.success(translate('signUp.accountVerification.success'));
          goToLogin('email');
        },
      });
      goToRoute('verify');
    } else {
      message.success(translate('signUp.success'));
      goToLogin('email');
    }
  };

  const onError = (e) => {
    captchaEnabled && captchaRef?.current?.reset();
    if (e?.status === 409) {
      message.error(translate('signUp.duplicate.email'));
    } else {
      message.error(translate('signUp.error'));
    }
  };

  const elements = [
    {
      type: 'text',
      name: 'name',
      label: 'Name',
      required: true,
      options: {
        initialValue: isDemoEnabled ? 'Test User' : undefined,
      },
    },
    {
      type: 'email',
      name: 'email',
      label: 'Email Address',
      required: true,
      options: {
        initialValue: isDemoEnabled ? 'admin@admin.com' : undefined,
      },
    },
    {
      type: 'password',
      name: 'password',
      label: 'Password',
      required: true,
      options: {
        initialValue: isDemoEnabled ? '123456' : undefined,
      },
    },
    {
      type: 'checkbox',
      name: 'terms',
      label: (
        <>
          I accept all the{' '}
          <Link routeKey="staticPages.terms" target="_blank">
            terms & conditions.
          </Link>
        </>
      ),
      requiredMessage: 'Please accept the terms and conditions',
      required: true,
      className: 'more-links',
    },
  ];

  if (captchaEnabled) {
    elements.push({
      type: 'custom',
      name: 'captcha',
      label: 'I am not a robot',
      required: true,
      requiredMessage: 'Please Enter the Captcha',
      Component: Captcha,
      inputProps: {
        ref: captchaRef,
      },
    });
  }

  return (
    <ApiForm
      elements={elements}
      onSuccess={onSuccess}
      onError={onError}
      submitButtonProps={{
        children: 'Sign up',
      }}
      apiUrl={url`register`}
      excludedFields={['terms']}
    />
  );
};

export const ButtonLink = () => (
  <Link routeKey="signup" routeParams={{ type: 'email' }}>
    <div className="auth-option">
      <span className="icon">
        <MailOutlined />
      </span>
      <p className="text">Email</p>
    </div>
  </Link>
);

export const Options = () => {
  return (
    <p className="option">
      Already have an account?{' '}
      <Link routeKey="login" routeParams={{ type: 'email' }}>
        Login
      </Link>
    </p>
  );
};
