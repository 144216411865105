import './styles.scss';
import { Menu } from 'antd';

export function PublicHeader() {
  return (
    <Menu
      mode="horizontal"
      className="Header"
      items={[
        {
          key: 'mail',
          label: <span className="head-logo">LOGO</span>,
        },
        {
          key: '6',
          className: 'head-butt1',
          label: <h3>Login</h3>,
        },
        {
          key: '7',
          className: 'head-butt2',
          label: <h3>Create Free Account</h3>,
        },
      ]}
    />
  );
}
