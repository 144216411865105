import { ProfileInformation } from './profileInformation';
/**
 * @description General Settings Page
 * @type Container
 * @author Inderdeep
 */
const Page = () => {
  return <ProfileInformation />;
};

Page.displayName = 'General-Settings';

Page.routeProps = {
  header: false,
};
export default Page;
