import { useCallback } from 'react';
import { url } from '../constants/api';
import { secureApi } from './api';
import { useDataState } from './useData';
export function useCurrentUser() {
  const [user, setUser] = useDataState('user');
  const getCurrentUser = useCallback(async () => {
    const data = await secureApi(url`users/current/get`);
    setUser(data);
    return data;
  }, []);

  const updateCurrentUser = useCallback(async (body) => {
    setUser({
      ...user,
      ...(await secureApi(url`users/current/update`, {
        method: 'PATCH',
        body,
      })),
    });
  }, []);

  return {
    user,
    getCurrentUser,
    updateCurrentUser,
  };
}
