import { useCurrentUser } from './useCurrentUser';

export function isFeatureAllowed(
  { allowedPermissions = [], allowedRoles = [] },
  { permissions = [], role = '' },
  defaultAdminRole = 'admin'
) {
  const isAdmin = role?.toLowerCase() === defaultAdminRole;
  let allowed = true;
  if (!isAdmin) {
    if (allowedRoles?.length) {
      allowed = allowed && allowedRoles.includes(role);
    }
    if (allowedPermissions?.length) {
      allowed =
        allowed &&
        permissions?.length &&
        allowedPermissions.every((v) => permissions.includes(v));
    }
  }
  return allowed;
}

export function useFeatureAccess() {
  const { user } = useCurrentUser();
  const { permissions, role } = user || {};

  return {
    hasAccess: (allowedRoles = [], allowedPermissions = []) =>
      isFeatureAllowed(
        { allowedPermissions, allowedRoles },
        {
          permissions,
          role,
        }
      ),
  };
}
