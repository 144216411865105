import { FormConfiguration } from '../../../containers/formConfiguration/index';

const EmailInformation = () => {
  return (
    <FormConfiguration
      configStartPrefix="NODEMAILER"
      pageTitle={'Email Settings'}
    />
  );
};

export default EmailInformation;
