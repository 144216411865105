import {
  DesktopOutlined,
  ControlOutlined,
  CarryOutOutlined,
  SnippetsOutlined,
  UserOutlined,
  IdcardOutlined,
  QuestionCircleOutlined,
  FileProtectOutlined,
  FileOutlined,
  BlockOutlined,
  FilePdfOutlined,
  SettingOutlined,
  MailOutlined,
} from '@ant-design/icons';

export const menuKeys = [
  {
    key: 'home.dashboard',
    name: 'sider.dashboard',
    icon: DesktopOutlined,
  },
  {
    key: 'home.users',
    name: 'sider.users',
    icon: UserOutlined,
    isAllowed: (permissions, role) => role?.toLowerCase() === 'admin',
  },
  {
    key: 'home.roles',
    name: 'sider.roles',
    icon: IdcardOutlined,
    role: 'admin',
  },
  {
    key: 'home.permission',
    name: 'sider.permissions',
    icon: CarryOutOutlined,
    role: 'admin',
  },

  {
    key: 'home.contact',
    name: 'sider.enquiries',
    icon: QuestionCircleOutlined,
    role: 'admin',
  },
  {
    key: 'home.configuration',
    name: 'sider.configurations',
    icon: FileProtectOutlined,
    role: 'admin',
  },
  {
    key: 'home.email-template',
    name: 'sider.email.templates',
    icon: MailOutlined,
    role: 'admin',
  },
  {
    key: 'home.pdf-template',
    name: 'sider.pdf.templates',
    icon: FilePdfOutlined,
    role: 'admin',
  },
  {
    key: 'home.theme',
    name: 'sider.theming',
    icon: BlockOutlined,
    role: 'admin',
  },
  {
    key: 'home.page',
    name: 'sider.static.pages',
    icon: FileOutlined,
    role: 'admin',
  },
  {
    key: 'home.page-configuration',
    name: 'sider.system.pages.configuration',
    icon: SnippetsOutlined,
    role: 'admin',
  },
  {
    key: 'home.system-log',
    name: 'System Logs',
    icon: SettingOutlined,
    role: 'admin',
  },
  {
    key: 'home.audit-log',
    name: 'Audit log',
    icon: ControlOutlined,
  },
  {
    key: 'home.unsubscribed-emails',
    name: 'Unsubscribed Emails',
    icon: MailOutlined,
    role: 'admin',
  },
  {
    key: 'home',
    name: 'System Settings',
    icon: SettingOutlined,
    role: 'admin',
    children: [
      {
        key: 'home.email-settings',
        name: 'Email Settings',
        icon: FilePdfOutlined,
      },
      {
        key: 'home.sms-settings',
        name: 'SMS Settings',
        icon: MailOutlined,
      },
    ],
  },
  { key: 'settings.general', name: 'sider.settings', icon: SettingOutlined },
];
