import './styles.scss';
import { Row, Col } from 'antd';
import { translate } from '../../../localization';

export function DashboardLayout({ cards = [] }) {
  return (
    <div className="dashboard">
      <div className="content">
        <p className="page-title mobile-only">{translate('dashboard.title')}</p>
        <Row gutter={16}>
          {cards.map((children, index) => {
            return (
              <Col key={index} xs={24} sm={12} md={12} lg={6} xl={6}>
                {children}
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
}
