import { WebCrud } from '../../../containers/crud/webCrud';
import { Crud } from '../../../containers/crud/crud';
import { PdfTemplateForm } from './form';

import { translate } from '../../../localization';
import { useCrudRecord } from '../../../containers/crud/recordContext';
import { Print } from './print';
export default function PdfTemplate() {
  return (
    <WebCrud
      api="html-template"
      query={{ search: 'type;pdf' }}
      FormComponent={PdfTemplateForm}
    >
      <Crud.Header title="PDF Templates">
        <Crud.Header.Actions.Common>
          <Crud.Header.Actions.Add
            modalProps={{
              className: 'large',
            }}
          />
        </Crud.Header.Actions.Common>
      </Crud.Header>
      <Crud.Table
        actions={[
          () => (
            <Crud.Table.RowActions.Edit
              modalProps={{
                className: 'large',
              }}
            />
          ),
          Crud.Table.RowActions.Delete,
          () => (
            <Crud.Table.RowActions.DuplicateRecordWithForm
              elements={[
                {
                  type: 'text',
                  label: 'Enter new Code',
                  name: 'code',
                  inputProps: {
                    placeholder: 'Code',
                  },
                },
              ]}
            />
          ),
          () => {
            const record = useCrudRecord();
            const { key } = record;
            return <Print template={key} data={{ type: 'pdf' }} />;
          },
        ]}
        columns={[
          {
            title: translate('template.key.label'),
            dataIndex: 'code',
          },
          {
            title: translate('pdfTemplate.name.label'),
            dataIndex: ['metadata', 'name'],
          },
        ]}
      >
        <Crud.Table.TableActions.Common />
        <Crud.Table.Header>
          <Crud.Search
            fields={[
              {
                type: 'text',
                name: 'code',
                inputProps: {
                  placeholder: 'Code',
                },
              },
            ]}
          ></Crud.Search>
        </Crud.Table.Header>
      </Crud.Table>
    </WebCrud>
  );
}
