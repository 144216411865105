import React from 'react';
import Link from '@arivaa-react/components/link';
import Form from '@arivaa-react/components/form';
import { Captcha } from '../../captcha';
import { PhoneOutlined } from '@ant-design/icons';
import { captchaEnabled, isDemoEnabled } from '../../../../hooks/useAppConfig';
import { translate } from '../../../../localization';
/**
 * @description Phone Login Form
 * @type Component
 * @author Inderdeep
 */
export const LoginForm = ({ login }) => {
  const captchaRef = React.useRef(null);
  /**
   * On Submit of  Form
   * @param event
   */
  const handleSubmit = async (values) => {
    captchaEnabled && captchaRef?.current?.reset();
    login({
      ...values,
      rememberMe: !!values.rememberMe,
      type: 'phoneLocal',
    });
  };

  return (
    <Form
      renderForm={({ renderElement }) => {
        return (
          <div className="form">
            {renderElement({
              type: 'phone',
              name: 'phone',
              label: 'Phone Number',
              required: true,
              options: {
                initialValue: isDemoEnabled ? '918146660945' : undefined,
              },
            })}
            {renderElement({
              type: 'password',
              name: 'password',
              label: 'Password',
              required: true,
              options: {
                initialValue: isDemoEnabled ? '123456' : undefined,
              },
            })}
            <div className="more-links">
              {renderElement({
                type: 'checkbox',
                name: 'rememberMe',
                label: 'Remember Me',
                after: (
                  <div className="forgot">
                    <Link routeKey={'forgot'} routeParams={{ type: 'phone' }}>
                      {translate('login.forgot')}
                    </Link>
                  </div>
                ),
              })}
            </div>
            {captchaEnabled
              ? renderElement({
                  type: 'custom',
                  name: 'captcha',
                  label: 'I am not a robot',
                  required: true,
                  requiredMessage: 'Please Enter the Captcha',
                  Component: Captcha,
                  inputProps: {
                    ref: captchaRef,
                  },
                })
              : null}
          </div>
        );
      }}
      onSubmit={handleSubmit}
      submitButtonProps={{
        children: 'Login',
      }}
    />
  );
};

export const ButtonLink = () => (
  <Link routeKey="login" routeParams={{ type: 'phone' }}>
    <div className="auth-option">
      <span className="icon">
        <PhoneOutlined />
      </span>
      <p className="text">Phone</p>
    </div>
  </Link>
);

export const Options = () => {
  return (
    <p className="option">
      Don't have an account?{' '}
      <Link routeKey="signup" routeParams={{ type: 'phone' }}>
        Sign up with Phone
      </Link>
    </p>
  );
};
