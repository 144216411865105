import { useEffect } from 'react';

import { CrudForm } from '../../../../containers/crud/crudForm';
import { url } from '../../../../constants/api';
import { useApiWithSideEffects } from '../../../../hooks/api';
import { translate } from '../../../../localization';
/**
 * @description User Form
 * @type Container
 * @author Inderdeep
 */
export const UserForm = (props) => {
  const { data } = props;
  const spinnerSelector = '.ant-form';
  const { data: roles, callApi } = useApiWithSideEffects({
    spinnerSelector,
  });

  useEffect(() => {
    callApi(url`roles`, {
      params: {
        all: true,
      },
    });
  }, []);

  return (
    <CrudForm
      {...props}
      elements={[
        ...(data
          ? [
              {
                type: 'file',
                name: 'photoURL',
                label: translate('user.phototURL.label'),
                fileCode: 'userImage',
              },
            ]
          : []),
        {
          type: 'text',
          name: 'name',
          label: translate('user.name.label'),
          required: true,
        },
        {
          type: 'email',
          name: 'email',
          label: translate('user.email.label'),
          required: true,
        },
        {
          type: 'password',
          name: 'password',
          label: translate('user.password.label'),
          required: !data,
        },
        {
          type: 'select',
          name: 'role',
          label: translate('user.role.label'),
          required: true,
          data: (roles || []).map(({ code: value, description: label }) => {
            return {
              label,
              value,
            };
          }),
        },
      ]}
      extra={
        !data
          ? {
              type: 'local',
            }
          : undefined
      }
    />
  );
};
