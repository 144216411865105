/**
 * Get Profile Picture from firebase
 * auth object
 * @param auth
 * @param config
 * @returns {string}
 */
export function getProfilePicture(auth, config) {
  let url = 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png';
  config = config || {};
  if (auth.photoURL) {
    url = auth.photoURL;
    if (url.indexOf('graph.facebook') != -1) {
      url = url + `?height=${config.height || 300}`;
    }
  }
  return url;
}
