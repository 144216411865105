import { FilterOutlined } from '@ant-design/icons';
import { ModalTrigger } from '@arivaa-react/components/modal';
import { Switch, FloatButton, Tooltip } from 'antd';
import { useRef } from 'react';
import { useTableData } from './tableContext';

export function ToggleColumns() {
  const {
    columns: allColumns,
    onToggleColumn,
    isColumnSelected,
  } = useTableData();
  const columns = allColumns.reduce((result, ele) => {
    result[ele.title] = isColumnSelected(ele);
    return result;
  }, {});
  const columnTitles = Object.keys(columns);
  const selectedColumns = Object.keys(columns).filter((key) => !!columns[key]);
  const switchRefs = useRef([]);
  const allColumnsSelected = selectedColumns.length === columnTitles.length;
  return (
    <ModalTrigger
      modalProps={{
        title: 'Toggle Column',
        footer: null,
        className: 'toggle-modal',
      }}
      content={
        <div className="toggle-list">
          <div className="item">
            <span className="text">All</span>
            <Switch
              checked={allColumnsSelected}
              onChange={(checked) => {
                onToggleColumn?.(
                  columnTitles.reduce((result, item) => {
                    result[item] = checked;
                    return result;
                  }, {})
                );
              }}
            />
          </div>
          {columnTitles.map((item, index) => {
            return (
              <div className="item" key={index}>
                <span className="text">{item}</span>
                <Switch
                  ref={(ref) => switchRefs.current.push(ref)}
                  checked={!!columns[item]}
                  onChange={(checked) => {
                    onToggleColumn?.({
                      ...columns,
                      [item]: checked,
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      }
    >
      <Tooltip title={`Showing ${selectedColumns.length} columns`}>
        <FloatButton
          badge={
            !allColumnsSelected && {
              count: selectedColumns.length,
            }
          }
          icon={<FilterOutlined />}
        />
      </Tooltip>
    </ModalTrigger>
  );
}
