/**
 * Contains Routing for the application
 */

import { renderRoutes } from 'react-router-config';
import { Redirect } from 'react-router-dom';
import pages from './pages';

import Error404 from './pages/error404';
import { Main } from './containers/main/index';
import { StaticPage } from './containers/staticPage';
import { initializeRoutes } from '@arivaa-react/react-router5';
import { getAppConfig } from './hooks/useAppConfig';
import { isSSR } from '@arivaa-react/helpers/web';
import { createBrowserHistory, createMemoryHistory } from 'history';
export const history = isSSR() ? createMemoryHistory() : createBrowserHistory();

export const DEFAULT_SECURED_ROUTE = 'home.dashboard';
/**
 * Function to get default route to redirect
 * based on role of user
 * @param {*} user
 * @returns
 */
export function getDefaultSecuredRoute() {
  return DEFAULT_SECURED_ROUTE;
}
/**
 * Route keys & Paths Map
 * @type {{Object}}
 */
const MainRoute = {
  component: Main,
  children: {},
};

/**
 * Append Page Routes
 */
MainRoute.children = {
  ...MainRoute.children,
  ...pages,
};

/**
 * Create Static Pages we get from server
 * @returns
 */
const createStaticRoutes = () => {
  const staticPages = getAppConfig('staticPages');
  if (!staticPages) {
    return;
  }
  let pages = {};
  (staticPages || []).forEach((page) => {
    pages[page.route] = {
      url: page.route,
      component: StaticPage,
      routeProps: {
        html: page.html || '',
        title: page.title || '',
        public: true,
        cache: true,
      },
    };
  });
  MainRoute.children.staticPages = {
    component: ({ route }) => <div>{renderRoutes(route.routes)}</div>,
    url: 'pages',
    routeProps: {
      public: true,
      cache: true,
    },
    children: pages,
  };
};

// For Caching
let routes;

/**
 * Get Routes for initializing
 * @returns
 */
export const getRoutes = () => {
  if (routes) {
    return routes;
  }
  createStaticRoutes();
  routes = [
    {
      component: Main,
      routes: [
        {
          path: '/',
          exact: true,
          component: () => (
            <Redirect
              to={getAppConfig('dbConfigured') ? '/login' : '/create-db'}
            />
          ),
        },
        ...initializeRoutes(MainRoute.children, history, {
          error404Page: '/error404',
          getExtraProps: ({ path }) => {
            const isCurrentRoute =
              getAppConfig('initialPageUrl') === window.location.pathname;

            return {
              ...getPageConfiguration(path),
              pageData: isCurrentRoute ? getAppConfig('pageData') : undefined,
            };
          },
        }),
        {
          component: Error404,
        },
      ],
    },
  ];
  return routes;
};

/**
 * Route Declarations
 * Set publicHeader = false to disable publicHeader
 * Set public = true to allow guest access to a route
 * @type {XML}
 */
export const Routes = () => {
  return renderRoutes(getRoutes());
};

/**
 * Get Page Configuration for any specific page
 * @param {*} path
 * @returns
 */
export function getPageConfiguration(path) {
  let config = (getAppConfig('pageConfigurations') || []).find((config) => {
    return '/' + config.routeUrl === path;
  });
  config = config || {};
  let output = {};
  (config.properties || []).forEach(({ name, value }) => {
    output[name] = value;
  });
  return output;
}
