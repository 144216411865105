import { WebCrud } from '../../../containers/crud/webCrud';
import { Crud } from '../../../containers/crud/crud';
import { formatDate } from '@arivaa-react/helpers/date-util';
import { RoleForm } from './form';
import { translate } from '../../../localization';
export default function Roles() {
  return (
    <WebCrud api="roles" query={{ size: 10 }} FormComponent={RoleForm}>
      <Crud.Header title="Roles">
        <Crud.Header.Actions.Common />
      </Crud.Header>
      <Crud.Table
        actions={[
          ...Crud.Table.RowActions.Common,
          () => (
            <Crud.Table.RowActions.DuplicateRecordWithForm
              elements={[
                {
                  type: 'text',
                  label: 'Enter new role code',
                  name: 'code',
                  inputProps: {
                    placeholder: 'New code',
                  },
                },
              ]}
            />
          ),
        ]}
        columns={[
          {
            title: translate('role.code.title'),
            dataIndex: 'code',
          },
          {
            title: translate('role.description.title'),
            dataIndex: 'description',
          },
          {
            title: translate('role.createdAt'),
            render: (createdAt) => {
              return <p>{formatDate(createdAt)}</p>;
            },
          },
        ]}
      >
        <Crud.Table.TableActions.Common />
        <Crud.Table.Header>
          <Crud.Search
            fields={[
              {
                type: 'text',
                name: 'code',
                inputProps: {
                  placeholder: translate('role.code.title'),
                },
              },
            ]}
          />
          <Crud.Table.BulkRowActions.Common />
        </Crud.Table.Header>
      </Crud.Table>
    </WebCrud>
  );
}
