import React from 'react';
import Link from '@arivaa-react/components/link';
import Form from '@arivaa-react/components/form';
import { Captcha } from '../../captcha';
import { MailOutlined } from '@ant-design/icons';
import { captchaEnabled, isDemoEnabled } from '../../../../hooks/useAppConfig';
import { translate } from '../../../../localization';
/**
 * @description Email Login Form
 * @type Component
 * @author Inderdeep
 */

export const LoginForm = ({ login }) => {
  const captchaRef = React.useRef(null);

  /**
   * On Submit of  Form
   * @param event
   */
  const handleSubmit = async (values) => {
    captchaEnabled && captchaRef?.current?.reset();
    login({
      ...values,
      rememberMe: !!values.rememberMe,
      type: 'local',
    });
  };
  const elements = [];
  if (captchaEnabled) {
    elements.push({
      type: 'custom',
      name: 'captcha',
      label: 'I am not a robot',
      required: true,
      requiredMessage: 'Please Enter the Captcha',
      Component: Captcha,
      inputProps: {
        ref: captchaRef,
      },
    });
  }
  return (
    <Form
      renderForm={({ renderElement }) => {
        return (
          <div className="form">
            {renderElement({
              type: 'email',
              name: 'email',
              label: 'Email Address',
              required: true,
              options: {
                initialValue: isDemoEnabled ? 'admin@admin.com' : undefined,
              },
            })}
            {renderElement({
              type: 'password',
              name: 'password',
              label: 'Password',
              required: true,
              options: {
                initialValue: isDemoEnabled ? '123456' : undefined,
              },
            })}
            <div className="more-links">
              {renderElement({
                type: 'checkbox',
                name: 'rememberMe',
                label: 'Remember Me',
                after: (
                  <div className="forgot">
                    <Link routeKey={'forgot'} routeParams={{ type: 'email' }}>
                      {translate('login.forgot')}
                    </Link>
                  </div>
                ),
              })}
            </div>
          </div>
        );
      }}
      onSubmit={handleSubmit}
      submitButtonProps={{
        children: 'Login',
      }}
    />
  );
};

export const ButtonLink = () => (
  <Link routeKey="login" routeParams={{ type: 'email' }}>
    <div className="auth-option">
      <span className="icon">
        <MailOutlined />
      </span>
      <p className="text">Email</p>
    </div>
  </Link>
);

export const Options = () => {
  return (
    <>
      {/* <SocialSignin /> */}
      <p className="option">
        Don't have an account?{' '}
        <Link routeKey="signup" routeParams={{ type: 'email' }}>
          Sign up with email
        </Link>
      </p>
    </>
  );
};
