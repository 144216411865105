import { AuthFormWrapper } from '../../authFormWrapper/index';
import authenticationOptions from './options';
import { SocialSignin } from '../socialSignin';
import { useSpinner } from '../../../hooks/useSpinner';
import { useLogin } from '../useLogin';

/**
 * @description Login Form
 * @type Component
 * @author Inderdeep
 */
export const LoginForm = ({ type }) => {
  const { login } = useLogin(useSpinner('.auth-form'));
  const { LoginForm, Options, title } = authenticationOptions[type] || {};
  const optionKeys = Object.keys(authenticationOptions);
  return (
    <AuthFormWrapper title={title || 'Log In'}>
      {LoginForm && <LoginForm login={login} />}
      {optionKeys.length > 1 && (
        <div className="separator">
          <span className="line" />
          <span>OR CONTINUE WITH</span>
          <span className="line" />
        </div>
      )}
      <div className="links">
        {optionKeys.map((key) => {
          const { ButtonLink } = authenticationOptions[key];
          if (type === key) {
            return null;
          } else {
            return ButtonLink && <ButtonLink key={key} login={login} />;
          }
        })}
        <SocialSignin />
      </div>
      {Options && <Options login={login} />}
    </AuthFormWrapper>
  );
};
