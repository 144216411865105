/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
export const StaticPage = ({ title, html }) => {
  return (
    <div className="public-page terms">
      <div className="container">
        <div className="section">
          <div className="heading">
            {title && title !== '' && (
              <div className="title">
                <p>{title}</p>
              </div>
            )}
          </div>
          <div className="details">
            <div
              className="editor-content"
              dangerouslySetInnerHTML={{ __html: html }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
