import { AuthFormWrapper } from '../../authFormWrapper/index';
import authenticationOptions from './options';

/**
 * @description Forgot Form
 * @type Component
 * @author Inderdeep
 */
export const ForgotForm = ({ type, login }) => {
  const { ForgotForm, Options, title, description } =
    authenticationOptions[type] || {};
  const optionKeys = Object.keys(authenticationOptions);
  return (
    <AuthFormWrapper
      title={title || 'Forgot Password'}
      description={description || 'You will receive a reset password code'}
    >
      {ForgotForm && <ForgotForm />}
      {optionKeys.length > 1 && (
        <div className="separator">
          <span className="line" />
          <span>OR RESET WITH</span>
          <span className="line" />
        </div>
      )}
      <div className="links">
        {optionKeys.map((key) => {
          const { ButtonLink } = authenticationOptions[key];
          if (type === key) {
            return null;
          } else {
            return ButtonLink && <ButtonLink key={key} login={login} />;
          }
        })}
      </div>
      {Options && <Options />}
    </AuthFormWrapper>
  );
};
