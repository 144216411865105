import { useCallback } from 'react';
import Link from '@arivaa-react/components/link';
import { goToRoute } from '@arivaa-react/react-router5';
import { PhoneOutlined } from '@ant-design/icons';
import { useVerificationData } from '../../useVerificationData';
import { ApiForm } from '../../../apiForm';
import { url } from '../../../../constants/api';
import { isDemoEnabled } from '../../../../hooks/useAppConfig';
import { translate } from '../../../../localization';

/**
 * @description Forgot Form
 * @type Component
 * @author Inderdeep
 */

export const ForgotForm = () => {
  const { setVerificationData } = useVerificationData();
  /**
   * On Success of  Call
   * @param event
   */
  const onSuccess = useCallback(async ({ key, verificationCode }) => {
    setVerificationData({
      confirmationKey: key,
      confirmationType: 'mobile number',
      verificationCode,
      api: 'password-reset-phone/verify',
      apiConfig: {
        method: 'PATCH',
      },
      resendApi: 'password-reset-phone/resend',
      onVerify: ({ token }, key) => {
        goToRoute('reset-password', {
          search: new URLSearchParams({
            type: 'phone',
            key,
            token,
          }).toString(),
        });
      },
    });
    goToRoute('verify');
  }, []);
  return (
    <ApiForm
      elements={[
        {
          type: 'phone',
          name: 'phoneNumber',
          label: 'Phone Number',
          required: true,
          options: {
            initialValue: isDemoEnabled ? '918146660945' : undefined,
          },
        },
      ]}
      apiUrl={url`password-reset-phone`}
      submitButtonProps={{ children: 'Reset Password' }}
      successMessage={translate('forgot.success')}
      errorMessage={translate('forgot.error')}
      onSuccess={onSuccess}
    />
  );
};

export const ButtonLink = () => (
  <Link routeKey="forgot" routeParams={{ type: 'phone' }}>
    <div className="auth-option">
      <span className="icon">
        <PhoneOutlined />
      </span>
      <p className="text">Phone</p>
    </div>
  </Link>
);

export const Options = () => {
  return (
    <p className="option">
      Clicked by mistake?{' '}
      <Link routeKey="login" routeParams={{ type: 'phone' }}>
        Login
      </Link>
    </p>
  );
};
