import './browser';
import App from './client/app';
import { hydrateRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import './client/styles';
import {history} from './client/routes'
hydrateRoot(
  document.getElementById('root'),
    <Router history={history}>
      <App />
    </Router>
);

if (module.hot) {
  module.hot.accept();
}
