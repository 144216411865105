import { ResetPasswordForm } from '../../containers/authentication/resetPasswordForm';

/**
 * @description Login
 * @type Page
 * @author
 */
const ResetPassword = () => {
  return (
    <div>
      <ResetPasswordForm />
    </div>
  );
};

ResetPassword.routeProps = {
  header: true,
  public: true,
  guestOnly: true,
};

ResetPassword.displayName = 'Reset-Password';
export default ResetPassword;
