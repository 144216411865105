import { parseJSON } from '@arivaa-react/helpers/common';
export function useUrlHistoryForSearch(searchQueryParamName = 'search') {
  const updateUrl = (urlSearchParams) => {
    const url = new URL(window.location.href);
    url.search = urlSearchParams;
    history.pushState({}, '', url.toString());
  };
  return {
    clearSearchFromUrl: () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      urlSearchParams.delete(searchQueryParamName);
      updateUrl(urlSearchParams);
    },
    saveSearchInUrl: (value) => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      urlSearchParams.set(searchQueryParamName, JSON.stringify(value));
      updateUrl(urlSearchParams);
    },
    getSearchFromUrl: () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      return parseJSON(urlSearchParams.get(searchQueryParamName)) || {};
    },
  };
}
