import { Error } from '../../containers/error/index';
const Error500 = () => (
  <Error
    code="500"
    title="Something went wrong"
    description="Sorry, we had me technical problems during your last operation."
  />
);

Error500.routeProps = {
  header: true,
  public: true,
  guestOnly: true,
};

export default Error500;
