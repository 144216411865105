/**
 * Dummy Browser for server side rendering
 * Normally we get window as undefined while server side rendering so to
 * tackle that where we know that we will need certain properties globally
 * in window or any other browser objects, Use this dummy browser
 *
 */
import config from '../config';

if (typeof window == 'undefined') {
  global.window = {};
  window.navigator = {
    userAgent: 'test',
  };
  window.location = {};
  window.addEventListener = () => {};
}

if (window.app === undefined) {
  window.app = {
    ...window.app,
  };
  window.app.server = config.isApiProxyEnabled
    ? config.API_PREFIX
    : config.SERVER;
  window.app.socialLogin = config.socialLogin;
  window.app.demo = process.env.NODE_ENV !== 'production';
  window.app.DEFAULT_COUNTRY =
    process.env.NODE_ENV === 'development' ? 'in' : undefined;
}
