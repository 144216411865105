import { delay } from '@arivaa-react/helpers/common';
import { useEffect } from 'react';
import { message } from 'antd';
import { goToRoute } from '@arivaa-react/react-router5';
import { goToLogin } from './defaultLoginLink';
import { useAuth } from '../../hooks/useAuth';
import { translate } from '../../localization';
import { useVerificationData } from './useVerificationData';

export function useLogin(spinner) {
  const { login, logout, auth } = useAuth();
  const { setVerificationData } = useVerificationData();
  useEffect(() => {
    if (auth) {
      logout();
      goToLogin();
    }
  }, []);
  const onLoginHandler = async (values) => {
    let loggedIn = false;
    let isDisabled = false;
    let response;
    spinner.start();
    try {
      response = await login(values);
      loggedIn = true;
    } catch (e) {
      console.error('Error while logging in ', { e });
      response = e?.response;
      if (e?.status === 403 && e?.details?.code === 'USER_NOT_VERIFIED') {
        const { confirmationKey, verificationCode } = e?.details || {};
        const isPhoneBased = values.phone;
        setVerificationData({
          confirmationKey,
          verificationCode,
          confirmationType: isPhoneBased ? 'mobile number' : 'email',
          api: isPhoneBased ? 'confirm-phone' : 'confirm-email',
          apiConfig: {
            method: 'PATCH',
          },
          resendApi: isPhoneBased
            ? 'confirm-phone/resend'
            : 'confirm-email/resend',
          onVerify: () => {
            message.success(translate('signUp.accountVerification.success'));
            goToLogin();
          },
        });
        goToRoute('verify');
        spinner.stop();
        return;
      } else if (e?.response?.status === 403) {
        isDisabled = true;
      }
    }
    if (loggedIn) {
      await delay(1);
      if (auth && auth.emailVerified) {
        message.success(translate('login.success'));
      }
    } else {
      if (isDisabled) {
        message.error(translate('login.disabled'));
      } else {
        message.error(response?.data?.message || translate('login.invalid'));
      }
    }
    spinner.stop();
    return response;
  };
  return {
    login: onLoginHandler,
  };
}
