import { Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { useCrudData } from '../crudContext';
import { translate } from '../../../localization';

export function Refresh() {
  const { refresh, loading } = useCrudData();
  return (
    <Button
      type="primary"
      icon={<SyncOutlined spin={loading} />}
      onClick={refresh}
    >
      <span className="text">{translate('crud.refresh')}</span>
    </Button>
  );
}
