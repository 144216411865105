import useWatchForm from '@arivaa-react/components/form/useWatchForm';
import React, { useEffect, useState } from 'react';
import { ApiForm } from '../../containers/apiForm';
import { url } from '../../constants/api';
import { useAutoCallApi, useSecureApi } from '../../hooks/api';

const CreateDb = () => {
  const [elements, setElements] = useState([]);
  const { data } = useAutoCallApi(useSecureApi, url`recreateDB`);
  const ref = React.useRef();
  const db = useWatchForm(ref, 'db');
  useEffect(() => {
    if (data && db) setElements(data[db]);
  }, [db, data]);

  return (
    <ApiForm
      ref={ref}
      preSubmit={(values) => {
        const { initialData, db, ...data } = values;
        return {
          data,
          initialData,
          db,
        };
      }}
      onSuccess={(_, { spinner }) => {
        spinner.start();
        setTimeout(async () => {
          await fetch('/refresh-config');
          spinner.stop();
          window.location.href = '/login';
        }, 15000);
      }}
      errorMessage={
        'An error occurred while creating the Database. Please try again'
      }
      elements={[
        {
          type: 'select',
          name: 'db',
          label: 'Select the database',
          required: 'true',
          data: Object.keys(data || {}),
        },
        ...elements,
        {
          type: 'switch',
          name: 'initialData',
          label: 'Create Initial Data',
        },
      ]}
      apiUrl={url`recreateDB`}
    />
  );
};
CreateDb.routeProps = {
  header: false,
};
export default CreateDb;
