import TelephoneInput from '@arivaa-react/components/phoneInput';
import { ApiForm } from '../../../containers/apiForm';

/**
 * @description Sample Component
 * @type Component
 * @author Inderdeep
 */

export const TestForm = function (props) {
  const { placeholder, label, isPhone, data, ...rest } = props;

  return (
    <ApiForm
      {...rest}
      elements={[
        isPhone
          ? {
              type: 'custom',
              Component: TelephoneInput,
              name: 'to',
              label,
            }
          : {
              type: 'text',
              label,
              inputProps: {
                placeholder,
              },
              name: 'to',
            },
      ]}
      preSubmit={(values) => {
        return {
          ...values,
          ...data,
        };
      }}
      submitButtonProps={{
        children: 'Save',
      }}
    />
  );
};
