import { ChangeEmailPassword } from './changeEmailPassword';
/**
 * @description Security Page
 * @type Container
 * @author Inderdeep
 */
const Page = () => {
  return <ChangeEmailPassword />;
};

Page.displayName = 'Security-Page';
Page.routeProps = {
  header: false,
};
export default Page;
