import React, { useCallback } from 'react';
import './styles.scss';
import BlockEditorComponent from '@arivaa-react/components/blockEditor';
import createSmartInput from '@arivaa-react/components/createSmartInput';
import { url } from '../../constants/api';
import { useTheme } from '../../hooks/useTheme';
import { message } from 'antd';
import { secureApi } from '../../hooks/api';
/**
 * @description Block Editor Component
 * @type Component
 * @author Inderdeep
 */
export const BlockEditor = createSmartInput(
  React.forwardRef(
    (props) => {
      const { themeString } = useTheme();
      const uploadImage = useCallback(async (blobInfo) => {
        const blob = blobInfo.blob();
        // To prevent duplicate uploads after a file has been uploaded
        if (!blob.name) {
          return;
        }
        const messageKey = Math.random();
        message.open({
          type: 'loading',
          content: `Uploading ${blob.name}...`,
          duration: 0,
          key: messageKey,
        });
        try {
          const body = new FormData();
          body.append('fileCode', 'ckEditorFile');
          body.append('file', blob);
          body.append('type', blob.type);
          body.append('name', blob.name);
          const data = await secureApi(url`storage`, {
            method: 'POST',
            body,
          });
          message.success(`${blob.name} uploaded successfully`);
          message.destroy(messageKey);
          return data?.url;
        } catch (e) {
          console.error('Error while uploading image', e);
          message.destroy(messageKey);
          throw e;
        }
      }, []);
      return (
        <BlockEditorComponent
          {...props}
          editorProps={{
            ...props?.editorProps,
            init: {
              images_upload_handler: uploadImage,
              content_style: `:root {${themeString}}`,
              ...props?.editorProps?.init,
              skin: 'oxide',
              content_css: 'default',
            },
          }}
        />
      );
    },
    {
      defaultValue: '',
    }
  )
);
