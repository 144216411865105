import { ForgotForm } from '../../containers/authentication/forgotForm/index';

/**
 * @description Forgot Password Via Phone
 * @type Page
 * @author
 */
const ForgotPhone = () => {
  return (
    <>
      <ForgotForm isPhoneBased={true} />
    </>
  );
};

ForgotPhone.displayName = 'Forgot-Phone';
ForgotPhone.routeProps = {
  header: true,
  public: true,
  guestOnly: true,
};
export default ForgotPhone;
