import Link from '@arivaa-react/components/link';
import { OtpForm } from './otpForm';
import { PhoneOutlined } from '@ant-design/icons';
import { isDemoEnabled } from '../../../../hooks/useAppConfig';
/**
 * @description Email Login Form
 * @type Component
 * @author Inderdeep
 */

export const LoginForm = () => {
  const elements = [
    {
      type: 'phone',
      name: 'phone',
      label: 'Phone Number',
      required: true,
      options: {
        initialValue: isDemoEnabled ? '918146660945' : undefined,
      },
    },
  ];
  return <OtpForm type={'phone'} elements={elements} />;
};

export const ButtonLink = () => (
  <Link routeKey="login" routeParams={{ type: 'phone-otp' }}>
    <div className="auth-option">
      <span className="icon">
        <PhoneOutlined />
      </span>
      <p className="text">Phone OTP</p>
    </div>
  </Link>
);
