import Link from '@arivaa-react/components/link';
export function DashboardCard({ route, count, Icon, label }) {
  return (
    <Link routeKey={route}>
      <div className="dashboard-card">
        <div className="info">
          <div className="details">
            <p className="count">{count || 0}</p>
            <p className="label">{label}</p>
          </div>
          <div className="icon-cont">
            <Icon className="icon" />
          </div>
        </div>
      </div>
    </Link>
  );
}
