import './styles.scss';
import { Layout } from 'antd';
import { Sider } from './sider';
import { renderRoutes } from 'react-router-config';
import Link from '@arivaa-react/components/link';
import { Header } from '../../containers/header';

import { mobileAndTabletcheck } from '@arivaa-react/helpers/web';
import { useDrawer } from '../../hooks/useDrawer';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Page = ({ route }) => {
  const { toggleDrawer, drawer } = useDrawer();
  const { header } = route;
  return (
    <div className="setting-page">
      <Layout style={{ minHeight: '100vh' }}>
        <Sider />
        {drawer && mobileAndTabletcheck() ? (
          <Link className="overlay" onClick={() => toggleDrawer(drawer)} />
        ) : null}
        <Layout className="setting-page-layout">
          {header !== false ? <Header type={header} /> : null}
          <div className="content">{renderRoutes(route.routes)}</div>
        </Layout>
      </Layout>
    </div>
  );
};

Page.displayName = 'Settings Page';

Page.routeProps = {
  header: 'secured',
  public: false,
  footer: false,
};
export default Page;
