import { useEffect } from 'react';
import { useApiWithSideEffects } from '../../../hooks/api';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { DashboardLayout } from './dashboardLayout';
import { DashboardCard } from './dashboardCard';
import { TeamOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { url } from '../../../constants/api';
import { translate } from '../../../localization';
const Page = () => {
  const { role } = useCurrentUser().user;

  const spinnerSelector = 'dashboard';
  const { data: dashboard, callApi } = useApiWithSideEffects({
    spinnerSelector,
  });

  useEffect(() => {
    callApi(url`dashboard/stats`, {
      params: {
        all: true,
      },
    });
  }, []);
  const { USER, roles } = dashboard || {};

  const dashboardCards = {
    USER: [],
    ADMIN: [
      <DashboardCard
        key={1}
        label={translate('common.users')}
        count={USER}
        Icon={TeamOutlined}
        route={'home.users'}
      />,
      <DashboardCard
        key={2}
        label={translate('common.roles')}
        count={roles}
        Icon={SafetyCertificateOutlined}
        route={'home.roles'}
      />,
    ],
  };

  return <DashboardLayout cards={dashboardCards[role]} />;
};

export default Page;
