import { useEffect, useRef } from 'react';
import { CrudForm } from '../../../../containers/crud/crudForm';
import { ALPHANUMERIC_HYPENS } from '@arivaa-react/components/regex';
import { Checkbox, Table } from 'antd';
import './styles.scss';
import { useSecureApi } from '../../../../hooks/api';
import { url } from '../../../../constants/api';
import { translate } from '../../../../localization';
import createSmartInput from '@arivaa-react/components/createSmartInput';

const MultiSelectTable = createSmartInput(
  ({ onChange, data = [], value = [] }) => {
    const dataSource = {};
    data.forEach((e) => {
      const [key, value] = e.key.split('_');
      //for crud permission we store the check for all defined permissions such as READ, CREATE etc
      //which is used to defined table data in the later section
      if (e.type === 'CRUD') {
        dataSource[key] = [...(dataSource[key] || []), value];
      }
      //for custom permission we directly construct the table data
      else {
        dataSource[key] = {
          feature: e.description,
          permissions: [{ label: '', value: e.key }],
        };
      }
    });

    const tableData = Object.keys(dataSource).map((key) => {
      const permissions = dataSource[key];
      return permissions instanceof Array
        ? {
            feature: key,
            permissions: permissions.map((value) => {
              return {
                label: value,
                value: key + '_' + value,
              };
            }),
          }
        : permissions;
    });

    const columns = [
      {
        title: 'Feature',
        dataIndex: 'feature',
        key: 'feature',
        render: (text) => <p>{text}</p>,
      },
      {
        title: 'Permissions',
        dataIndex: 'permissions',
        key: 'permissions',
        render: (permissions = []) => {
          return (
            <>
              {permissions.map((permission, index) => {
                return (
                  <Checkbox
                    key={index}
                    checked={value.includes(permission.value)}
                    onChange={(e) => {
                      const { checked } = e.target;
                      onChange(
                        checked
                          ? [...value, permission.value]
                          : value.filter((p) => p !== permission.value)
                      );
                    }}
                  >
                    {permission.label}
                  </Checkbox>
                );
              })}
            </>
          );
        },
      },
    ];
    return (
      <Table dataSource={tableData} columns={columns} pagination={false} />
    );
  }
);
/**
 * @description Role Form
 * @type Container
 * @author Inderdeep
 */
export const RoleForm = (props) => {
  const { data } = props;
  const form = useRef(null);
  const {
    data: permissions,
    callApi: getPermissions,
    loading,
  } = useSecureApi();

  useEffect(() => {
    getPermissions(url`permission`, {
      params: {
        all: true,
      },
    });
  }, []);

  return (
    <CrudForm
      {...props}
      skipTranslate={true}
      ref={form}
      renderForm={({ renderElement }) => {
        return (
          <div className="form">
            <div className="inline">
              {renderElement({
                type: 'text',
                name: 'code',
                label: translate('role.code.title'),
                required: true,
                options: {
                  rules: [
                    {
                      pattern: ALPHANUMERIC_HYPENS,
                      message:
                        'Code can only be alphanumeric and can contain only hyphens (-)',
                    },
                  ],
                },
                inputProps: {
                  disabled: !!data,
                  className: 'uppercase',
                },
              })}
              {renderElement({
                type: 'text',
                name: 'description',
                label: translate('role.description.title'),
                required: true,
              })}
            </div>
            {renderElement({
              type: 'custom',
              name: 'permissions',
              label: translate('role.permission.placeholder'),
              required: false,
              inputProps: {
                mode: 'multiple',
                loading,
                data: permissions,
              },
              Component: MultiSelectTable,
            })}
          </div>
        );
      }}
      preSubmit={(values) => {
        if (!data) {
          values.key = values?.key?.toUpperCase();
        }
        return values;
      }}
    />
  );
};
