import './styles.scss';
import { SocialSignIn } from '@arivaa-react/components/socialSignIn';
import { jsUcfirst } from '@arivaa-react/helpers/common';
import Link from '@arivaa-react/components/link';

import {
  FacebookOutlined,
  GoogleOutlined,
  LinkedinOutlined,
  TwitterOutlined,
} from '@ant-design/icons';
import { useLogin } from '../useLogin';
import { useSpinner } from '../../../hooks/useSpinner';
import { getAppConfig } from '../../../hooks/useAppConfig';
const ProviderComponents = {
  google: GoogleOutlined,
  facebook: FacebookOutlined,
  linkedin: LinkedinOutlined,
  twitter: TwitterOutlined,
};
/**
 * @description Social Sign In Container
 * @type Container
 * @author Inderdeep
 */
export const SocialSignin = () => {
  const { login } = useLogin(useSpinner('body'));
  const providers = getAppConfig('socialLogin') || {};
  const socialLoginCredentials = getAppConfig('socialLoginCredentials');
  const providerLength = Object.keys(providers).length;

  return providerLength > 0 ? (
    <>
      {Object.keys(providers).map((name, index) => {
        name = name || '';
        const clientId =
          socialLoginCredentials?.[name.toUpperCase() + '_LOGIN_KEY'];
        const clientSecret =
          socialLoginCredentials?.[name.toUpperCase() + '_LOGIN_SECRET'];
        if (name !== '' && !clientId) {
          console.error('No Client Id found for social sign type ' + name);
        }
        const Icon = ProviderComponents[providers[name].icon];
        return providers[name] && clientId ? (
          <SocialSignIn
            config={{
              ...providers[name],
              redirectUri: window.location.origin + '/oauth2-callback',
              type: name,
              clientId,
              clientSecret,
            }}
            onSuccess={async (response = {}) => {
              login({
                token: response[providers[name]?.tokenAttributeName],
                secret: response[providers[name]?.secretAttributeName],
                ...providers[name]?.extra,
                type: 'social',
                provider: name,
              });
            }}
            key={index}
          >
            <Link>
              <div className="auth-option">
                {providers[name].icon && (
                  <span className={`icon ${name.toLowerCase()}`}>
                    {Icon && <Icon />}
                  </span>
                )}
                <p className="text">{jsUcfirst(name.toLowerCase())}</p>
              </div>
            </Link>
          </SocialSignIn>
        ) : null;
      })}
    </>
  ) : null;
};
