import {
  AppstoreOutlined,
  ArrowLeftOutlined,
  SafetyCertificateOutlined,
} from '@ant-design/icons';
export const menuKeys = [
  {
    key: 'home.dashboard',
    name: 'sider.settings.back',
    icon: ArrowLeftOutlined,
  },
  {
    key: 'settings.general',
    name: 'sider.settings.general',
    icon: AppstoreOutlined,
  },
  {
    key: 'settings.security',
    name: 'sider.settings.security',
    icon: SafetyCertificateOutlined,
  },
];
