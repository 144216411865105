import { useDataState } from './useData';

export function useDrawer() {
  const [drawer = true , setDrawer] = useDataState('drawer');
  return {
    drawer,
    setDrawer,
    toggleDrawer : () => {
      setDrawer(!drawer)
    }
  };
}
