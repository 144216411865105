import { BulkDelete } from './bulkDelete';
import { useTableData } from './tableContext';

export function BulkRowActions({ children }) {
  const { selection } = useTableData();
  return selection.selectedRowKeys?.length > 0 ? children : null;
}

BulkRowActions.Common = ({ children }) => (
  <BulkRowActions>
    <BulkDelete />
    {children}
  </BulkRowActions>
);
