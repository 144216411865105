import { Button, message, Tooltip } from 'antd';
import ModalTrigger from '@arivaa-react/components/modal/modal-trigger';
import { CopyOutlined } from '@ant-design/icons';
import { useCrudData } from '../crudContext';
import { ApiForm } from '../../../containers/apiForm';
import { useCrudRecord } from '../recordContext';

export function DuplicateRecordWithForm(props) {
  const { apiUrl, refresh } = useCrudData();
  const record = useCrudRecord();
  const FormComponent = ({ hideModal }) => (
    <ApiForm
      elements={[
        {
          type: 'text',
          label: 'Enter new key',
          name: 'key',
          inputProps: {
            placeholder: 'New Key',
          },
        },
      ]}
      {...props}
      apiUrl={`${apiUrl}/duplicate`}
      spinningSelector={'.duplicate-form'}
      onSuccess={() => {
        hideModal?.();
        refresh();
      }}
      onError={(e) => {
        if (e?.response?.status === 409) {
          message.error('This key already exists in our system');
        } else {
          console.error('Error while initiating your request', e);
          message.error('Error while initiating your request');
        }
      }}
      preSubmit={(values) => ({
        id: record.key,
        ...values,
      })}
    />
  );
  return (
    <ModalTrigger
      content={<FormComponent />}
      modalProps={{
        title: 'Duplicate',
        footer: null,
        className: 'duplicate-form',
      }}
    >
      <Tooltip title="Duplicate">
        <Button className="btn yellow-btn-text">
          <CopyOutlined />
        </Button>
      </Tooltip>
    </ModalTrigger>
  );
}
