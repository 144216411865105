import { Button } from 'antd';
import { ModalTrigger } from '@arivaa-react/components/modal';
import { PlusOutlined } from '@ant-design/icons';
import { useCrudData } from '../crudContext';
import { translate } from '../../../localization';
export function AddRecord({ modalProps }) {
  const { FormComponent } = useCrudData();
  return FormComponent ? (
    <ModalTrigger
      modalProps={{
        title: 'Create',
        className: `add-record-modal  ${modalProps?.className}`,
        footer: null,
        ...modalProps,
      }}
      content={<FormComponent />}
    >
      <Button type="primary" icon={<PlusOutlined />}>
        <span className="text">{translate('crud.create')}</span>
      </Button>
    </ModalTrigger>
  ) : null;
}
