import { Button, Tooltip, Switch } from 'antd';
import { UpdateEntity } from '../../../containers/updateEntity';
import { formatDate } from '@arivaa-react/helpers/date-util';
import {
  LoadingOutlined,
  CheckOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { UserForm } from './form';
import { WebCrud } from '../../../containers/crud/webCrud';
import { Crud } from '../../../containers/crud/crud';
import { Table } from '../../../containers/crud/table';
import { translate } from '../../../localization';
import { useAutoCallApi, useSecureApi } from '../../../hooks/api';
import { url } from '../../../constants/api';
import { useCrudData } from '../../../containers/crud/crudContext';
import { useCrudRecord } from '../../../containers/crud/recordContext';
import { UpdatableDropDown } from './updatableDropDown';

export default function Users() {
  const { refresh } = useCrudData();
  const { data = [] } = useAutoCallApi(useSecureApi, url`roles`, {
    params: {
      all: true,
    },
  });

  const options = data.map((ele) => {
    return {
      label: ele.code,
      value: ele.code,
    };
  });
  return (
    <WebCrud api="users" FormComponent={UserForm}>
      <Crud.Header title="Users">
        <Crud.Header.Actions.Common />
      </Crud.Header>
      <Crud.Table
        actions={[
          ...Table.RowActions.Common,
          () => {
            const record = useCrudRecord();
            return (
              <UpdateEntity
                keyPropName="uid"
                getChildren={({ loading, updateEntity }) => {
                  return !record.emailVerified ? (
                    <Tooltip placement="top" title={'Approve'}>
                      <Button
                        className="edit"
                        onClick={async () => {
                          if (loading) {
                            return false;
                          }
                          await updateEntity({ emailVerified: true });
                          refresh();
                        }}
                      >
                        {loading ? <LoadingOutlined /> : <CheckOutlined />}
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip placement="top" title={'Approved'}>
                      <Button className="edit">
                        <CheckCircleOutlined />
                      </Button>
                    </Tooltip>
                  );
                }}
              />
            );
          },
          () => (
            <Crud.Table.RowActions.DuplicateRecordWithForm
              elements={[
                {
                  type: 'text',
                  label: 'Enter new email',
                  name: 'email',
                  inputProps: {
                    placeholder: 'Email',
                  },
                },
              ]}
            />
          ),
        ]}
        columns={[
          {
            title: translate('user.name.label'),
            dataIndex: 'displayName',
          },
          {
            title: translate('user.type.label'),
            dataIndex: 'providerData',

            render: (providerData) => {
              const [provider] = providerData || [];
              const { providerId } = provider || {};
              return <p>{providerId || 'password'}</p>;
            },
          },
          {
            title: translate('user.email.label'),
            dataIndex: 'email',
          },
          {
            title: 'Mobile Number',
            dataIndex: 'phoneNumber',
          },
          {
            title: 'Role',
            dataIndex: 'role',
            width: 120,
            render: (_, record) => {
              return (
                <UpdatableDropDown
                  defaultValue={record.role}
                  updatePropName={'role'}
                  options={options}
                />
              );
            },
          },
          {
            title: translate('user.enabled'),
            dataIndex: 'disabled',
            render: (_, record) => {
              return (
                <UpdateEntity
                  keyPropName="uid"
                  getChildren={({ loading, updateEntity }) => {
                    return record.role &&
                      record.role.toLowerCase() !== 'admin' ? (
                      <Switch
                        loading={loading}
                        checked={!record.disabled}
                        onChange={async (checked) => {
                          await updateEntity({ disabled: !checked });
                          refresh();
                        }}
                      />
                    ) : null;
                  }}
                />
              );
            },
          },
          {
            title: 'Email Verified',
            dataIndex: 'emailVerified',
            render: (_, record) => {
              return (
                <UpdateEntity
                  keyPropName="uid"
                  getChildren={({ loading, updateEntity }) => {
                    return record.role &&
                      record.role.toLowerCase() !== 'admin' ? (
                      <Switch
                        loading={loading}
                        checked={record.emailVerified}
                        onChange={async (checked) => {
                          await updateEntity({ emailVerified: checked });
                          refresh();
                        }}
                      />
                    ) : null;
                  }}
                />
              );
            },
          },
          {
            title: 'Phone Verified',
            dataIndex: 'phoneVerified',
            render: (_, record) => {
              return (
                <UpdateEntity
                  keyPropName="uid"
                  getChildren={({ loading, updateEntity }) => {
                    return record?.role &&
                      record?.role.toLowerCase() !== 'admin' ? (
                      <Switch
                        loading={loading}
                        checked={record.phoneVerified}
                        onChange={async (checked) => {
                          await updateEntity({ phoneVerified: checked });
                          refresh();
                        }}
                      />
                    ) : null;
                  }}
                />
              );
            },
          },
          {
            title: translate('user.createdAt'),
            dataIndex: 'createdAt',
            render: (createdAt) => {
              return <p>{formatDate(createdAt)}</p>;
            },
          },
        ]}
      >
        <Crud.Table.TableActions.Common />
        <Crud.Table.Header>
          <Crud.Search
            fields={[
              {
                type: 'text',
                name: 'displayName',
                inputProps: {
                  placeholder: 'Name',
                },
              },
              {
                type: 'email',
                name: 'email',
                inputProps: {
                  placeholder: 'Email',
                },
              },
              {
                type: 'text',
                name: 'phoneNumber',
                inputProps: {
                  placeholder: 'Phone Number',
                },
              },
            ]}
          ></Crud.Search>
          <Crud.Table.BulkRowActions.Common />
        </Crud.Table.Header>
      </Crud.Table>
    </WebCrud>
  );
}
