import { url } from '../../../constants/api';
import { SyncOutlined } from '@ant-design/icons';
import { WebCrud } from '../../../containers/crud/webCrud';
import { Crud } from '../../../containers/crud/crud';
import { ConfigurationForm } from './form';
import { translate } from '../../../localization';
import { Space } from 'antd';
import {
  applyPromiseToasterEffects,
  useApi,
  useSecureApi,
} from '../../../hooks/api';
import { DecryptConfig } from './decryptConfig';

export default function Configuration() {
  const refreshConfigApi = useApi();
  const refreshIndexesApi = useSecureApi();
  return (
    <WebCrud api="configuration" FormComponent={ConfigurationForm}>
      <Crud.Header title="Configuration">
        <Crud.Header.Actions>
          <Crud.Header.Actions.Common
            secondaryActions={[
              <Space
                key="refresh-config"
                onClick={() =>
                  applyPromiseToasterEffects(
                    refreshConfigApi.callApi('refresh-config'),
                    {
                      successMessage:
                        'Refreshing Config initiated. Might take about a minute',
                    }
                  )
                }
              >
                <SyncOutlined spin={refreshIndexesApi.loading} />
                Refresh Config
              </Space>,
              <Space
                key="refresh-indexes"
                onClick={() =>
                  applyPromiseToasterEffects(
                    refreshIndexesApi.callApi(url(`initialize-indexes`)),
                    {
                      successMessage:
                        'Refreshing indexes initiated. Might take about a minute',
                    }
                  )
                }
              >
                <SyncOutlined spin={refreshIndexesApi.loading} /> Refresh
                Indexes
              </Space>,
            ]}
          />
        </Crud.Header.Actions>
      </Crud.Header>

      <Crud.Table
        actions={[
          ...Crud.Table.RowActions.Common,
          () => (
            <Crud.Table.RowActions.DuplicateRecordWithForm
              elements={[
                {
                  type: 'text',
                  label: 'Enter new key',
                  name: 'key',
                  inputProps: {
                    placeholder: 'Key',
                  },
                },
              ]}
            />
          ),
        ]}
        columns={[
          {
            dataIndex: 'key',
            title: translate('configuration.key.label'),
          },
          {
            title: translate('configuration.type.label'),
            dataIndex: 'type',
          },
          {
            title: translate('configuration.value.label'),
            dataIndex: 'value',
            render: (value, { key, type, encrypted }) => {
              if (value === null || typeof value === 'undefined') {
                value = '';
              }
              return type === 'file' ? (
                <div>
                  <div className="image">
                    <img
                      src={value}
                      style={{
                        width: 200,
                        marginBottom: 10,
                      }}
                    />
                  </div>
                  <a target="_blank" href={value} rel="noreferrer">
                    Download
                  </a>
                </div>
              ) : encrypted ? (
                <DecryptConfig configKey={key} />
              ) : (
                <div>{value.toString()}</div>
              );
            },
          },
          {
            title: translate('configuration.description.label'),
            dataIndex: 'description',
          },
        ]}
      >
        <Crud.Table.Header>
          <Crud.Search
            fields={[
              {
                type: 'text',
                name: 'key',
                inputProps: {
                  placeholder: 'Key',
                },
              },
            ]}
          />
          <Crud.Table.BulkRowActions.Common />
        </Crud.Table.Header>
        <Crud.Table.TableActions.Common />
      </Crud.Table>
    </WebCrud>
  );
}
