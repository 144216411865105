import Form from '@arivaa-react/components/form';
import { ModalTrigger, useModal } from '@arivaa-react/components/modal';
import { useCallback } from 'react';
import { notification, message, Space } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { useCrudData } from '../crudContext';
import { secureApi } from '../../../hooks/api';
import { useSpinner } from '../../../hooks/useSpinner';
const spinningSelector = '.upload-modal';

function CSVToArray(csv) {
  var lines = csv.split('\n');
  var result = [];
  var headers = lines[0].split(',');

  for (var i = 1; i < lines.length; i++) {
    var currentLine = lines[i].split(',');

    if (currentLine.join('').trim() === '') {
      // Skip empty lines
      continue;
    }

    var obj = {};
    for (var j = 0; j < headers.length; j++) {
      var key = headers[j].replace(/"/g, '').trim();
      var value = currentLine[j].replace(/"/g, '').trim();

      // Exclude empty values
      if (value !== '') {
        obj[key] = value;
      }
    }

    result.push(obj);
  }

  return result;
}

const openNotification = ({ title, description, duration }) => {
  notification.open({
    message: title,
    description,
    duration,
    placement: 'topRight',
  });
};

export function CsvImport(props) {
  const spinner = useSpinner(spinningSelector);
  const { hideModal } = useModal();
  const { apiUrl, refresh } = useCrudData();
  const handleSubmit = useCallback(async ({ csv }) => {
    try {
      const csvData = Buffer.from(
        csv['dataUri'].split(',')[1],
        'base64'
      ).toString('utf-8');
      const body = CSVToArray(csvData);
      const res = await secureApi(`${apiUrl}/import`, {
        method: 'POST',
        body,
      });
      hideModal();
      refresh?.();
      const unsuccessfulEntries = [];
      res.map((val, i) => {
        if (val[i] === false) {
          unsuccessfulEntries.push(i + 1);
        }
      });
      openNotification({
        title: 'Import Successful',
        description: (
          <>
            {res.length - unsuccessfulEntries.length} Successful Entries.{' '}
            {unsuccessfulEntries.length} Unsuccessful Entries.{' '}
            {unsuccessfulEntries.length > 0
              ? `Unsuccessful row numbers : ${unsuccessfulEntries.join(',')}`
              : ''}
          </>
        ),
        duration: unsuccessfulEntries.length === 0 ? 4.5 : 0,
      });
    } catch (e) {
      spinner.stop();
      message.error(
        e?.response?.data?.message || 'An error occured. Please try again'
      );
    }
  }, []);

  return (
    <ModalTrigger
      modalProps={{
        title: 'Upload',
        footer: null,
        className: 'upload-modal',
      }}
      content={
        <Form
          elements={[
            {
              type: 'file',
              name: 'csv',
              label: 'Upload CSV File',
              required: true,
            },
          ]}
          onSubmit={handleSubmit}
          submitButtonProps={{
            children: 'Upload',
          }}
        />
      }
      {...props.modalTriggerProps}
    >
      <Space>
        <ImportOutlined /> Import{' '}
      </Space>
    </ModalTrigger>
  );
}
