import { useUrlHistoryForSearch } from '../../hooks/useUrlHistoryForSearch';
import { Crud } from './crud';

export function WebCrud(props) {
  const { getSearchFromUrl } = useUrlHistoryForSearch();
  const searchQuery = getSearchFromUrl();
  return (
    <Crud
      {...props}
      /**
       * Fire the first effect if no search query present
       * else don't fire it to avoid 2 api calls on first mount
       * when search is present and url is loaded
       */
      autoCallApiOnMount={Object.keys(searchQuery).length === 0}
    />
  );
}
