import { useState } from 'react';
import { url } from '../../../constants/api';
import { ApiForm } from '../../../containers/apiForm';
import { ModalTrigger } from '@arivaa-react/components/modal';
import { EyeOutlined } from '@ant-design/icons';

export function DecryptConfig({
  configKey: key,
  AfterDecryptComponent = ({ defaultValue }) => defaultValue,
  ...props
}) {
  const [decryptedValue, setDecryptedValue] = useState();
  return decryptedValue ? (
    <AfterDecryptComponent {...props} defaultValue={decryptedValue} />
  ) : (
    <ModalTrigger
      modalProps={{
        title: 'Authorize',
        footer: null,
      }}
      content={
        <ApiForm
          apiUrl={url`configuration/decrypt`}
          elements={[
            {
              type: 'password',
              name: 'password',
              label: 'Password',
              required: true,
            },
          ]}
          extra={{ key }}
          onSuccess={(response) => setDecryptedValue(response?.value)}
        />
      }
    >
      <EyeOutlined />
    </ModalTrigger>
  );
}
