import { getCurrentRoute, goToRoute } from '@arivaa-react/react-router5';
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from './useAuth';
import { useAfterAuth } from './useAfterAuth';
import { addResponseInterceptor } from './api';
import { useSpinner } from './useSpinner';
import { useLocation } from 'react-router-dom';
import { goToLogin } from '../containers/authentication/defaultLoginLink';

export const useOnPageSetup = () => {
  const route = getCurrentRoute();
  const { auth, logout, refresh } = useAuth();
  /**
   * Page is considered ready initially only if
   * page is public and user not logged in irrespective of guesonly so we don't need
   * to do anything
   */
  const [pageReady, setPageReady] = useState(!auth && route.public);

  const spinner = useSpinner('body');
  const location = useLocation();

  useAfterAuth(() => {
    setPageReady(true);
  });

  const logoutUser = useCallback(() => {
    /**
     * Refresh whole page in order to reset the whole state
     */
    logout();
    goToRoute('login', {
      forceRefresh: true,
    });
  });

  useEffect(() => {
    (async () => {
      /**
       * If page is not ready by default that means either page is not public or user is authenticated already
       *  and we need to check for credentials
       */
      if (!pageReady) {
        try {
          /**
           * If the route is secure or guest only with refresh token available, then indicate the refresh
           */
          if (auth?.refreshToken) {
            spinner.start();
            await refresh({
              refreshToken: auth.refreshToken,
            });
          } else {
            throw new Error('User not logged in');
          }
        } catch (e) {
          console.debug('User not logged in ', { e });
          //if refresh fails in a secure route
          goToLogin({
            search: `_next=${encodeURIComponent(location.pathname)}${
              location.search
                ? `&_next_query=${encodeURIComponent(
                    location.search.substring(1)
                  )}`
                : ''
            }`,
          });
          // Set page ready in case refresh fails
          setPageReady(true);
        } finally {
          spinner.stop();
        }
      }
    })();
    addResponseInterceptor((response) => {
      if (response.status === 401) {
        logoutUser(true);
      }
    });
  }, []);

  return {
    pageReady,
  };
};
