import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useSyncExternalStore,
} from 'react';

const DataContext = createContext({});

const useStore = (initalValue) => {
  const store = useRef(initalValue);
  const get = useCallback(() => store.current, []);
  const subscribers = useRef(new Set());
  const set = useCallback((value) => {
    store.current = { ...store.current, ...value };
    return subscribers.current.forEach((callback) => callback());
  }, []);

  const subscribe = useCallback((callback) => {
    subscribers.current.add(callback);
    return () => subscribers.current.delete(callback);
  }, []);

  return { get, set, subscribe };
};

export function useData(selector) {
  const { subscribe, set, get } = useContext(DataContext);
  const state = useSyncExternalStore(
    subscribe,
    () => selector?.(get()),
    () => selector?.(get())
  );
  return [state, set];
}

export function useDataSelector(selector) {
  const { subscribe, get } = useContext(DataContext);
  const selectorFn =
    typeof selector === 'string' ? () => get()[selector] : selector?.(get());
  const state = useSyncExternalStore(subscribe, selectorFn, selectorFn);
  return state;
}

export function useDataState(stateName) {
  // Check if stateName is a string
  if (typeof stateName !== 'string') {
    throw new Error('stateName must be a string to useDataState hook');
  }
  const { subscribe, set, get } = useContext(DataContext);
  const selector = () => get()[stateName];
  const state = useSyncExternalStore(subscribe, selector, selector);
  return [state, (value) => set({ [stateName]: value })];
}

export function DataProvider({ children, value: initialValue }) {
  return (
    <DataContext.Provider value={useStore(initialValue)}>
      {children}
    </DataContext.Provider>
  );
}
